var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ValidationObserver",
    {
      ref: "updateForm",
      staticClass: "w-screen min-contents",
      class: [_vm.isImpersonated ? "mb-4" : "mb-10"],
      attrs: { tag: "div" },
    },
    [
      _c("div", { staticClass: "container mx-auto grid grid-cols-2 mt-2" }, [
        _c(
          "h2",
          { staticClass: "col-span-1 text-4xl text-left font-semibold" },
          [
            _vm.provider && _vm.provider.id
              ? _c("span", [
                  _vm._v(
                    "[" + _vm._s(_vm._f("padZero")(_vm.provider.id)) + "]"
                  ),
                ])
              : _vm._e(),
            _vm.provider && _vm.provider.company_name
              ? _c("span", [_vm._v(_vm._s(_vm.provider.company_name))])
              : _vm._e(),
          ]
        ),
        _vm.providerEdit
          ? _c("div", { staticClass: "col-span-1 text-right font-bold" }, [
              _c(
                "button",
                {
                  staticClass: "py-2 px-4 mr-2 action_btn",
                  on: {
                    click: function ($event) {
                      _vm.providerEdit = !_vm.providerEdit
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("controls.cancel")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "py-2 px-4 ml-2 action_btn",
                  on: { click: _vm.updateProvider },
                },
                [_vm._v(_vm._s(_vm.$t("controls.save")))]
              ),
            ])
          : _c("div", { staticClass: "col-span-1 text-right font-bold" }, [
              _vm.loginUserRole === "manager"
                ? _c(
                    "button",
                    {
                      staticClass: "py-2 px-4 bg-light-yellow mr-2 action_btn",
                      on: {
                        click: function ($event) {
                          return _vm.impersonatedLogin(
                            "provider",
                            _vm.provider.uuid
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("pages.administrators.commons.impersonate")
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass:
                    "py-2 px-4 bg-base-yellow text-black font-semibold text-sm",
                  on: {
                    click: function ($event) {
                      _vm.providerEdit = !_vm.providerEdit
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("controls.edit")) + " ")]
              ),
            ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "container mx-auto grid grid-cols-12 gap-6 mt-6 items-center",
        },
        [
          _c(
            "label",
            { staticClass: "col-start-1 col-span-4 py-2 text-right" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.terms_and_conditions")))]
          ),
          _c("div", { staticClass: "col-span-8 py-2" }, [
            _vm.provider.agree_to_terms
              ? _c(
                  "button",
                  {
                    staticClass:
                      "h-full w-full py-1 bg-base-yellow text-xl font-semibold text-black text-center",
                    on: { click: _vm.downloadPdf },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("commons.fields.download_agreed")) +
                        " "
                    ),
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass:
                      "h-full w-full py-1 bg-green-400 font-bold text-center",
                    on: {
                      click: function ($event) {
                        return _vm.$modal.show("tacModal")
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("pages.consumers.profile.open_tac_screen")
                        ) +
                        " "
                    ),
                  ]
                ),
          ]),
          _c(
            "label",
            { staticClass: "col-start-1 col-span-4 py-2 text-right" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.invoice_number")))]
          ),
          _vm.providerEdit
            ? _c(
                "div",
                { staticClass: "col-span-8" },
                [
                  _c(
                    "FlCheckbox",
                    {
                      staticClass: "mb-4",
                      on: {
                        change: function ($event) {
                          _vm.inputProvider.invoice_number = null
                        },
                      },
                      model: {
                        value: _vm.hasInvoice,
                        callback: function ($$v) {
                          _vm.hasInvoice = $$v
                        },
                        expression: "hasInvoice",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("pages.providers.profile.has_invoice")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("FlLabeledInput", {
                    attrs: {
                      disabled: !_vm.inputProvider.has_invoice,
                      sublabel: _vm.$t(
                        "pages.providers.profile.invoice_number"
                      ),
                      rule: { invoice_number: true },
                      explanation:
                        "登録番号は「T + 13桁の数字」となります。Tが抜けていたり、数字の桁数がたりませんと、登録できません。",
                    },
                    model: {
                      value: _vm.inputProvider.invoice_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputProvider, "invoice_number", $$v)
                      },
                      expression: "inputProvider.invoice_number",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "col-span-8" },
                [
                  _vm.inputProvider.invoice_number
                    ? _c("FlShow", {
                        attrs: { value: _vm.inputProvider.invoice_number },
                      })
                    : _vm.inputProvider.has_invoice
                    ? _c("FlShow", {
                        attrs: {
                          value: _vm.$t("pages.providers.profile.has_invoice"),
                        },
                      })
                    : _c("FlShow", {
                        attrs: {
                          value: _vm.$t("pages.providers.profile.no_invoice"),
                        },
                      }),
                  _c("p", { staticClass: "text-sm mt-2" }, [
                    _vm._v(
                      "※登録する場合は右上の編集ボタンを押して情報を入力してください。"
                    ),
                  ]),
                ],
                1
              ),
          _c(
            "label",
            { staticClass: "col-start-1 col-span-4 py-2 text-right" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.company_name")))]
          ),
          _vm.providerEdit
            ? _c(
                "div",
                { staticClass: "col-span-7" },
                [
                  _c("fl-labeled-input", {
                    attrs: {
                      rules: {
                        required: _vm.requiresCompanyFields(),
                        max: 255,
                      },
                      fieldId: "company_name",
                    },
                    model: {
                      value: _vm.inputProvider.company_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputProvider, "company_name", $$v)
                      },
                      expression: "inputProvider.company_name",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "col-span-5 py-2" },
                [
                  _c("fl-show", {
                    attrs: { value: _vm.provider.company_name },
                  }),
                ],
                1
              ),
          _c(
            "label",
            { staticClass: "col-start-1 col-span-4 py-2 text-right" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.company_name_english")))]
          ),
          _vm.providerEdit
            ? _c(
                "div",
                { staticClass: "col-span-7" },
                [
                  _c("fl-labeled-input", {
                    attrs: {
                      rules: {
                        required: _vm.requiresCompanyFields(),
                        max: 255,
                        english: true,
                      },
                      fieldId: "company_name_english",
                    },
                    model: {
                      value: _vm.inputProvider.company_name_english,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputProvider, "company_name_english", $$v)
                      },
                      expression: "inputProvider.company_name_english",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "col-span-5 py-2" },
                [
                  _c("fl-show", {
                    attrs: { value: _vm.provider.company_name_english },
                  }),
                ],
                1
              ),
          _c(
            "label",
            { staticClass: "col-start-1 col-span-4 py-2 text-right" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.company_url")))]
          ),
          _vm.providerEdit
            ? _c(
                "div",
                { staticClass: "col-span-7" },
                [
                  _c("fl-labeled-input", {
                    attrs: {
                      rules: { required: false, max: 255 },
                      fieldId: "company_url",
                    },
                    model: {
                      value: _vm.inputProvider.company_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputProvider, "company_url", $$v)
                      },
                      expression: "inputProvider.company_url",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "col-span-5 py-2" },
                [_c("fl-show", { attrs: { value: _vm.provider.company_url } })],
                1
              ),
          _c(
            "label",
            { staticClass: "col-start-1 col-span-4 py-2 text-right" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.company_type")))]
          ),
          _vm.providerEdit
            ? _c(
                "div",
                { staticClass: "col-span-7" },
                [
                  _c("fl-select", {
                    attrs: {
                      required: true,
                      options: _vm.companyTypeProvider,
                      fieldId: "company_type",
                    },
                    model: {
                      value: _vm.inputProvider.company_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputProvider, "company_type", $$v)
                      },
                      expression: "inputProvider.company_type",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "col-span-7 h-10 py-2" },
                [
                  _c("fl-show", {
                    attrs: {
                      value: _vm.provider.company_type,
                      options: _vm.companyTypeProvider,
                    },
                  }),
                ],
                1
              ),
          _c(
            "label",
            { staticClass: "col-start-1 col-span-4 py-2 text-right" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.preferred_language")))]
          ),
          _vm.providerEdit
            ? _c(
                "div",
                { staticClass: "col-span-7" },
                [
                  _c("fl-select", {
                    attrs: {
                      required: true,
                      options: _vm.language,
                      fieldId: "preferred_language",
                    },
                    model: {
                      value: _vm.inputProvider.preferred_language,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputProvider, "preferred_language", $$v)
                      },
                      expression: "inputProvider.preferred_language",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "col-span-7 h-10 py-2" },
                [
                  _c("fl-show", {
                    attrs: {
                      value: _vm.provider.preferred_language,
                      options: _vm.language,
                    },
                  }),
                ],
                1
              ),
          _c(
            "label",
            { staticClass: "col-start-1 col-span-4 text-right py-2" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.provider_name")))]
          ),
          _vm.providerEdit
            ? _c(
                "div",
                { staticClass: "col-span-7 grid grid-cols-4 gap-x-6" },
                [
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c("fl-labeled-input", {
                        attrs: {
                          sublabel: _vm.$t("commons.fields.last_name"),
                          rules: { required: true, max: 255 },
                          fieldId: "last_name",
                        },
                        model: {
                          value: _vm.inputProvider.last_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputProvider, "last_name", $$v)
                          },
                          expression: "inputProvider.last_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c("fl-labeled-input", {
                        attrs: {
                          sublabel: _vm.$t("commons.fields.first_name"),
                          rules: { required: true, max: 255 },
                          fieldId: "first_name",
                        },
                        model: {
                          value: _vm.inputProvider.first_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputProvider, "first_name", $$v)
                          },
                          expression: "inputProvider.first_name",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "col-span-7 grid grid-cols-4 gap-x-6 h-10 py-2",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-span-1" },
                    [
                      _c("fl-show", {
                        attrs: { value: _vm.provider.last_name },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-1" },
                    [
                      _c("fl-show", {
                        attrs: { value: _vm.provider.first_name },
                      }),
                    ],
                    1
                  ),
                ]
              ),
          _c(
            "label",
            { staticClass: "col-start-1 col-span-4 text-right py-2" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.provider_name_english")))]
          ),
          _vm.providerEdit
            ? _c(
                "div",
                { staticClass: "col-span-7 grid grid-cols-4 gap-x-6" },
                [
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c("fl-labeled-input", {
                        attrs: {
                          sublabel: _vm.$t("commons.fields.last_name"),
                          rules: { required: false, max: 255, english: true },
                          fieldId: "last_name_english",
                        },
                        model: {
                          value: _vm.inputProvider.last_name_english,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputProvider,
                              "last_name_english",
                              $$v
                            )
                          },
                          expression: "inputProvider.last_name_english",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c("fl-labeled-input", {
                        attrs: {
                          sublabel: _vm.$t("commons.fields.first_name"),
                          rules: { required: false, max: 255, english: true },
                          fieldId: "first_name_english",
                        },
                        model: {
                          value: _vm.inputProvider.first_name_english,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputProvider,
                              "first_name_english",
                              $$v
                            )
                          },
                          expression: "inputProvider.first_name_english",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "col-span-7 grid grid-cols-4 gap-x-6 h-10 py-2",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-span-1" },
                    [
                      _c("fl-show", {
                        attrs: { value: _vm.provider.last_name_english },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-1" },
                    [
                      _c("fl-show", {
                        attrs: { value: _vm.provider.first_name_english },
                      }),
                    ],
                    1
                  ),
                ]
              ),
          _c(
            "label",
            { staticClass: "col-start-1 col-span-4 py-2 text-right" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.phone_number")))]
          ),
          _vm.providerEdit
            ? _c(
                "div",
                { staticClass: "col-span-7" },
                [
                  _c("ValidationProvider", {
                    staticClass:
                      "col-span-7 h-10 text-left flex items-center justify-start",
                    attrs: {
                      tag: "div",
                      rules: { required: _vm.requiresCompanyFields() },
                      name: "電話番号",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var errors = ref.errors
                            return [
                              _c("VuePhoneNumberInput", {
                                attrs: {
                                  translations: _vm.phoneFieldTranslations,
                                  "default-country-code":
                                    _vm.defaultCountryCode,
                                },
                                on: { update: _vm.getPhoneNumber },
                                model: {
                                  value: _vm.inputProvider.tel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputProvider, "tel", $$v)
                                  },
                                  expression: "inputProvider.tel",
                                },
                              }),
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: errors.length,
                                      expression: "errors.length",
                                    },
                                  ],
                                  staticClass: "text-xs text-red-600 mt-1",
                                },
                                [_vm._v(" " + _vm._s(errors[0]) + " ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4035953004
                    ),
                  }),
                ],
                1
              )
            : _c("div", { staticClass: "col-span-7 h-10 py-2" }, [
                _vm._v(
                  " " + _vm._s(_vm._f("phoneNumber")(_vm.provider.tel)) + " "
                ),
              ]),
          _c(
            "label",
            { staticClass: "col-start-1 col-span-4 py-2 text-right" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.mail_address")))]
          ),
          _vm.providerEdit
            ? _c(
                "div",
                { staticClass: "col-span-7" },
                [
                  _c("fl-labeled-input", {
                    attrs: {
                      rules: { required: true, max: 255 },
                      fieldId: "contry",
                    },
                    model: {
                      value: _vm.inputProvider.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputProvider, "email", $$v)
                      },
                      expression: "inputProvider.email",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "col-span-5 py-2" },
                [_c("fl-show", { attrs: { value: _vm.provider.email } })],
                1
              ),
          _c(
            "label",
            { staticClass: "col-start-1 col-span-4 py-2 text-right" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.timezone")))]
          ),
          _vm.providerEdit
            ? _c(
                "div",
                { staticClass: "col-span-7" },
                [
                  _c("fl-select", {
                    attrs: {
                      needsTranslation: false,
                      options: _vm.timezoneOptions,
                      fieldId: "timezone",
                    },
                    model: {
                      value: _vm.inputProvider.timezone,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputProvider, "timezone", $$v)
                      },
                      expression: "inputProvider.timezone",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "col-span-7 h-10 py-2 mb-6" },
                [
                  _c("fl-show", {
                    attrs: {
                      value: _vm.provider.timezone,
                      options: _vm.timezone,
                    },
                  }),
                ],
                1
              ),
          !_vm.providerEdit
            ? [
                _c(
                  "div",
                  { staticClass: "col-start-1 col-span-4 py-2 text-right" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("commons.fields.list_of_registered_works")
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-span-7 text-left py-2" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "h-full w-full",
                        attrs: { to: "/movies" },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "h-full w-full py-1 bg-very-light-grey text-xl font-semibold text-black",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("components.changeFieldButton.more")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.hasAuth
        ? _c("div", { staticClass: "bg-very-light-grey pb-10" }, [
            _c(
              "div",
              {
                staticClass:
                  "container mx-auto grid grid-cols-12 gap-6 mt-6 items-center pt-6",
              },
              [
                _c(
                  "label",
                  { staticClass: "col-start-1 col-span-5 py-2 text-right" },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("commons.fields.commision_percentage"))
                    ),
                  ]
                ),
                _vm.providerEdit
                  ? _c(
                      "div",
                      { staticClass: "col-span-7" },
                      [
                        _c("fl-labeled-input", {
                          attrs: {
                            fieldId: "fee_rate",
                            rule: { numeric: true },
                          },
                          model: {
                            value: _vm.inputProvider.fee_rate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.inputProvider,
                                "fee_rate",
                                _vm._n($$v)
                              )
                            },
                            expression: "inputProvider.fee_rate",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "col-span-5 py-2" },
                      [
                        _c("fl-show", {
                          attrs: { value: _vm.provider.fee_rate + "%" },
                        }),
                      ],
                      1
                    ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "container mx-auto grid grid-cols-12 gap-6 mt-6 items-start pt-6",
              },
              [
                _c(
                  "label",
                  { staticClass: "col-start-1 col-span-5 py-2 text-right" },
                  [_vm._v(_vm._s(_vm.$t("commons.fields.management_note")))]
                ),
                _vm.providerEdit
                  ? _c("div", { staticClass: "col-span-7" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.inputProvider.management_note,
                            expression: "inputProvider.management_note",
                          },
                        ],
                        staticClass: "w-full h-40 input_text",
                        domProps: { value: _vm.inputProvider.management_note },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.inputProvider,
                              "management_note",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ])
                  : _c("div", { staticClass: "col-span-5 py-2" }, [
                      _c(
                        "p",
                        { staticClass: "whitespace-pre-line break-words" },
                        [_vm._v(_vm._s(_vm.provider.management_note))]
                      ),
                    ]),
              ]
            ),
          ])
        : _vm._e(),
      _c("NoticeModal", {
        attrs: {
          modalName: "successNoticeModal",
          contents: _vm.noticeMessage,
          buttons: ["OK"],
        },
      }),
      _c(
        "modal",
        {
          attrs: {
            name: "tacModal",
            width: "80%",
            height: "80%",
            scrollable: false,
            adaptive: true,
          },
        },
        [
          _c("div", { staticClass: "relative" }, [
            _c(
              "button",
              {
                staticClass:
                  "absolute close_btn h-5 w-5 top-2 right-2 sm:h-5 sm:w-5 sm:m-2 z-50",
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/image/close_icon_gray.svg") },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("tacModal")
                    },
                  },
                }),
              ]
            ),
          ]),
          _c("FlShowTac", {
            attrs: {
              userType: "provider",
              acknowledgeType: "button",
              uuid: _vm.providerId,
            },
            on: {
              agree: function ($event) {
                return _vm.hideAndReload()
              },
            },
            model: {
              value: _vm.provider.agree_to_terms,
              callback: function ($$v) {
                _vm.$set(_vm.provider, "agree_to_terms", $$v)
              },
              expression: "provider.agree_to_terms",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }